<template>
  <fragment>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      style="width: 100%;"
    >
      <v-list
        dense
        class="py-0"
      >
        <!-- Logo y close button -->
        <div class="mt-2 mb-6 mx-4 d-flex justify-space-between align-center">
          <div style="width: 110px; height:34px">
            <v-img
              :lazy-src="`${urlS3}wamasolplus-03.png`"
              :src="`${urlS3}wamasolplus-03.png`"
              @click="goHome()"
            ></v-img>
          </div>
          <a @click="$emit('setDrawler')">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </a>
        </div>
        <!-- Sesion -->
        <v-list-item link>
          <v-list-item-content>
            <div class="d-flex justify-space-between align-center">
              <p
                class="mb-0 menuSupLinkMine standarMine"
                @click="$router.push({ name: 'auth-login' })"
              >
                <v-icon
                  medium
                  class="mr-1"
                >
                  {{ icons.mdiAccountCircle }}
                </v-icon>
                {{ $t('landing.lbl.login') }}
              </p>
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mx-4"></v-divider>

        <!-- idioma -->
        <v-list-item
          link
          @click.stop="idioma = !idioma"
        >
          <v-list-item-content>
            <div class="d-flex justify-space-between align-center">
              <p class="mb-0 menuSupLinkMine standarMine">
                {{ $t('landing.lbl.changeLang') }}
              </p>
              <div class="d-flex justify-space-between align-center">
                <!--<v-img
                      class="teamPhotoMine mr-2"
                      :lazy-src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
                      :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
                      :alt="$i18n.locale"
                      style="background-position: inherit;max-width: 25px; max-height:15px"
                    >
                    </v-img>-->
                <country-flag
                  :country="$i18n.locale === 'en' ? 'gb' : $i18n.locale"
                  class="mb-1 pt-0"
                />
                <p class="mb-0 menuSupLinkMine standarMine">
                  <!--{{ $i18n.locale }}-->
                </p>
                <v-icon>{{ icons.mdiChevronRight }}</v-icon>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>

        <!-- Navigation Drawer idioma -->
        <v-navigation-drawer
          v-model="idioma"
          right
          fixed
          style="width: 100%;"
        >
          <v-list
            dense
            class="py-0"
          >
            <div class="ma-4 d-flex justify-space-between align-center">
              <a
                class="d-flex justify-space-between align-center"
                @click.stop="drawer = !drawer"
              >
                <v-icon class="mr-4">{{ icons.mdiChevronLeft }}</v-icon>
                <p class="mb-0 menuSupLinkMine standarMine">
                  {{ $t('landing.lbl.selectLang') }}
                </p>
              </a>
              <a @click="$emit('setDrawler')">
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </a>
            </div>
            <v-divider></v-divider>
            <v-list-item
              v-for="locale in availableLocales"
              :key="locale.locale"
              :value="locale.locale"
              link
              class="mt-4"
              @click="setLocale(locale.locale)"
            >
              <v-list-item-content>
                <div class="d-flex justify-start align-center">
                  <!--<v-img
                        class="teamPhotoMine mr-2"
                        :lazy-src="locale.img"
                        :src="locale.img"
                        style="background-position: inherit;max-width: 25px; max-height:15px"
                      >
                      </v-img>-->
                  <country-flag
                    :country="locale.locale === 'en' ? 'gb' : locale.locale"
                    class="mb-1 pt-0 mr-2"
                  />
                  <p class="mb-0 menuSupLinkMine standarMine">
                    {{ locale.name }}
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <v-divider class="mx-4"></v-divider>

        <v-list-item
          link
          class="mt-4"
        >
          <v-list-item-content>
            <a
              href="#home"
              class="px-2"
            >
              <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.home') }}</p>
            </a>
          </v-list-item-content>
        </v-list-item>

        <p class="mb-0 mx-6 menuSupLinkMine standarMine py-2">
          {{ $t('landing.menu.about') }}
        </p>

        <v-list-item link>
          <v-list-item-content>
            <a
              href="#conecta"
              class="px-6"
            >
              <p class="mb-0 menuSupLinkMine">
                <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.plataform') }}
              </p>
            </a>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <a
              href="#orientada"
              class="px-6"
            >
              <p class="mb-0 menuSupLinkMine">
                <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.afiliate') }}
              </p>
            </a>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <a
              href="#div"
              class="px-6"
            >
              <p class="mb-0 menuSupLinkMine">
                <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.marcas') }}
              </p>
            </a>
          </v-list-item-content>
        </v-list-item>

        <!--<v-list-item link>
              <v-list-item-content>
                <a
                  href="#equipo"
                  class="px-6"
                >
                  <p class="mb-0 menuSupLinkMine">
                    <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.team') }}
                  </p>
                </a>
              </v-list-item-content>
            </v-list-item>-->

        <v-list-item link>
          <v-list-item-content>
            <a
              href="#historia"
              class="px-6"
            >
              <p class="mb-0 menuSupLinkMine">
                <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.history') }}
              </p>
            </a>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <a
              href="#productos"
              class="px-2"
            >
              <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.products') }}</p>
            </a>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <a
              href="#socios"
              class="px-2"
            >
              <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.parnert') }}</p>
            </a>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <a
              href="#testimonios"
              class="px-2 mr-2"
            >
              <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.testimonios') }}</p>
            </a>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="catalogs.length > 0"
          link
        >
          <v-list-item-content>
            <a
              class="px-2 mr-2"
              @click="$router.push({ name: 'catalogs' })"
            >
              <p class="mb-0 menuSupLinkMine">{{ $t('landing.catalogs') }}</p>
            </a>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="events.length > 0"
          link
        >
          <v-list-item-content>
            <a
              class="px-2 mr-2"
              @click="$router.push({ name: 'events' })"
            >
              <p class="mb-0 menuSupLinkMine">{{ $t('landing.eventos') }}</p>
            </a>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'
import {
  mdiPlus,
  mdiChevronDown,
  mdiMenu,
  mdiChevronRight,
  mdiAccountCircle,
  mdiChevronLeft,
  mdiClose,
} from '@mdi/js'

export default {
  props: {
    drawer: {
      type: Boolean,
    },
  },
  data() {
    return {
      idioma: false,
      urlLogin: `${process.env.VUE_APP_URL}login`,
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiChevronRight,
        mdiMenu,
        mdiAccountCircle,
        mdiChevronLeft,
        mdiClose,
      },
      events: [],
      catalogs: [],
    }
  },
  computed: {
    ...mapState({
      languages: state => state.app.languages,
    }),
    availableLocales() {
      return this.languages.filter(i => i.locale !== this.$i18n.locale)
    },
  },
  created() {
    this.fetchDataFromEndpoints()
  },
  methods: {
    goUrl(slug) {
      if (slug === 'catalogs') {
        this.$router.push({ name: 'catalogs' })
      } else if (slug === 'events') {
        this.$router.push({ name: 'events' })
      } else if (slug === 'home') {
        this.$router.push({ name: 'home' })
        setTimeout(() => {
          this.scrollInto('home')
        }, 100)
      }
    },
    setLocale(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('lenguaje', locale)
    },
    // eslint-disable-next-line no-unused-vars
    scrollInto(elementId) {
      this.drawer = false
    },
    redirectPage() {
      // window.open(this.urlLogin, '_blank')
      const route = this.$router.resolve({
        name: 'auth-login',
      })
      window.open(route.href, '_blank')
    },
    goHome() {
      this.$router.push({ name: 'home' })
    },
    async fetchDataFromEndpoints() {
      try {
        const [
          resEvent,
          resCatalog,
        ] = await Promise.all([
          this.axios
            .post('catalogs/list-events', { actives: true }),
          this.axios
            .post('catalogs/list-catalogos', { active: true }),
        ])

        this.events = resEvent.data.data
        this.catalogs = resCatalog.data.data
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
  },
}
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

a {
  text-decoration: none;
}

.menuSupLinkMine {
  color: black;
  font-family: OpenSansRegular;
}
</style>
