<template>
  <div>
    <v-container
      id="nuestros"
      grid-list-sm
      class="px-5 mt-16"
    >
      <v-row>
        <v-col
          cols="12"
          md="5"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-img
                class="mr-10"
                :lazy-src="`${urlS3}footerLogo.png`"
                :src="`${urlS3}footerLogo.png`"
                style="max-width: 150px;"
              >
              </v-img>
              <p class="whiteMine pr-15 punt14Mine OpenSansLight">
                {{ $t('landing.secFooter.body') }}
              </p>
            </v-col>
          </v-row>

          <!--PAYMENTS-->
          <!--LINE 1-->
          <v-row
            no-gutters
            outlined
            align="left"
            justify="left"
          >
            <v-col cols="12">
              <v-img
                :lazy-src="require('@/assets/home/footer/line1.jpg')"
                :src="require('@/assets/home/footer/line1.jpg')"
                max-height="40px"
                width="100%"
              >
              </v-img>
            </v-col>
          </v-row>
          <!--LINE 2-->
          <v-row
            no-gutters
          >
            <v-col
              cols="6"
              lg="6"
              md="6"
              sm="6"
              align="left"
              justify="left"
            >
              <v-img
                :lazy-src="require('@/assets/home/footer/line2.jpg')"
                :src="require('@/assets/home/footer/line2.jpg')"
                max-height="40px"
                aspect-ratio="2"
                class="mt-2"
                height="auto"
                contain
                style="text-align: left;"
              >
              </v-img>
            </v-col>
            <v-col
              v-if="showPT"
              cols="6"
              lg="6"
              md="6"
              sm="6"
              align="right"
              justify="right"
            >
              <v-img
                :lazy-src="require('@/assets/home/footer/line3.jpg')"
                :src="require('@/assets/home/footer/line3.jpg')"
                max-height="40px"
                aspect-ratio="2"
                class="mt-2"
                contain
              >
              </v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="mt-5"
        >
          <p class="whiteMine punt32Mine OpenSansRegular">
            {{ $t('landing.secFooter.service') }}
          </p>
          <ul>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">
                {{ $t('landing.secFooter.serv1') }}
              </p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">
                {{ $t('landing.secFooter.serv2') }}
              </p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">
                {{ $t('landing.secFooter.serv3') }}
              </p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">
                {{ $t('landing.secFooter.serv4') }}
              </p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">
                {{ $t('landing.secFooter.serv5') }}
              </p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">
                {{ $t('landing.secFooter.serv6') }}
              </p>
            </li>
            <li style="color:white">
              <p class="mb-0 whiteMine punt14Mine OpenSansLight">
                {{ $t('landing.secFooter.serv7') }}
              </p>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="mt-5"
        >
          <p class="whiteMine punt32Mine OpenSansRegular">
            {{ $t('landing.secFooter.about') }}
          </p>
          <ul class="whiteMine">
            <li>
              <a
                class="menuSupLinkMine whiteMine punt14Mine OpenSansLight"
                @click="scrollInto('conecta')"
              >
                {{ $t('landing.secFooter.about1') }}
              </a>
            </li>
            <li>
              <a
                class="menuSupLinkMine whiteMine punt14Mine OpenSansLight"
                @click="scrollInto('orientada')"
              >
                {{ $t('landing.secFooter.about2') }}
              </a>
            </li>
            <li>
              <a
                class="menuSupLinkMine whiteMine punt14Mine OpenSansLight"
                @click="scrollInto('div')"
              >
                {{ $t('landing.secFooter.about3') }}
              </a>
            </li>
            <!--<li>
              <a
                href="#equipo"
                class="menuSupLinkMine whiteMine punt14Mine OpenSansLight"
              >
                {{ $t('landing.secFooter.about4') }}
              </a>
            </li>-->
            <li>
              <a
                class="menuSupLinkMine whiteMine punt14Mine OpenSansLight"
                @click="scrollInto('historia')"
              >
                {{ $t('landing.secFooter.about5') }}
              </a>
            </li>

            <li>
              <a
                class="menuSupLinkMine whiteMine punt14Mine OpenSansLight"
                @click="$router.push({ name: 'terms' })"
              >
                {{ $t('landing.terms.title') }}
              </a>
            </li>
            <li>
              <a
                class="menuSupLinkMine whiteMine punt14Mine OpenSansLight"
                @click="$router.push({ name: 'policy' })"
              >
                {{ $t('landing.policy.title') }}
              </a>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="mt-5"
        >
          <p class="whiteMine punt32Mine OpenSansRegular">
            {{ $t('landing.secFooter.contact') }}
          </p>
          <div class="d-block mb-2">
            <div class="d-flex justify-start align-start">
              <v-icon
                color="white"
                small
              >
                {{ icons.mdiPhoneInTalkOutline }}
              </v-icon>
              <p class="mb-0 px-2 whiteMine punt14Mine OpenSansLight">
                +351 910 697 161 (Portugal)<br />
                +34 610 84 46 02 (España)<br />
                +53 5276 0703 (Cuba)<br />
                +502 4066 6270 (Guatemala)
              </p>
            </div>
          </div>
          <div class="d-block mb-2">
            <div class="d-flex justify-start align-center">
              <v-icon
                color="white"
                small
              >
                {{ icons.mdiWeb }}
              </v-icon>
              <p class="mb-0 px-2 whiteMine punt14Mine OpenSansLight">
                wamasol.com
              </p>
            </div>
          </div>
          <div class="d-block mb-2">
            <div class="d-flex justify-start align-start">
              <v-icon
                class="mt-1"
                color="white"
                small
              >
                {{ icons.mdiMapMarkerOutline }}
              </v-icon>
              <p class="mb-0 px-2 whiteMine punt14Mine OpenSansLight">
                Rua Augusto Rosa, número 79, Porto, 4000-098 Porto, Portugal
              </p>
            </div>
          </div>
          <v-divider
            class="mt-16"
            style="border-color:white"
          ></v-divider>
          <div class="d-flex justify-end align-center">
            <a
              href="https://wa.me/351910697161"
              class="socialMine"
              target="_blank"
            >
              <v-icon color="white">{{ icons.mdiWhatsapp }}</v-icon>
            </a>
            <a
              href="https://facebook.com/WamasolTours"
              class="socialMine"
              target="_blank"
            >
              <v-icon color="white">{{ icons.mdiFacebook }}</v-icon>
            </a>
            <a
              href="https://twitter.com/WamasolTours"
              class="socialMine"
              target="_blank"
            >
              <!--<v-icon color="white">{{ icons.mdiTwitter }}</v-icon>-->
              <XTwitter
                class="mt-2"
                color="#fff"
              />
            </a>
            <a
              href="https://instagram.com/wamasoltours"
              class="socialMine"
              target="_blank"
            >
              <v-icon color="white">{{ icons.mdiInstagram }}</v-icon>
            </a>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="12"
          sm="12"
          class="text-center"
        >
          <p class="whiteMine pr-15 punt14Mine OpenSansLight">
            COPYRIGHT © 2023-2024 {{ $t('landing.footer.powerd') }} {{ $t('landing.footer.allRight') }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiChevronDown,
  mdiPhoneInTalkOutline,
  mdiEmailOutline,
  mdiMapMarkerOutline,
  mdiWhatsapp,
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiWeb,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import XTwitter from '@/views/utils/images/x_twitter.vue'

export default {
  components: {
    XTwitter,
  },
  setup() {
    return {
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiPhoneInTalkOutline,
        mdiEmailOutline,
        mdiMapMarkerOutline,
        mdiWhatsapp,
        mdiFacebook,
        mdiInstagram,
        mdiTwitter,
        mdiWeb,
      },
      showPT: false,
    }
  },
  data: () => ({
    model: null,
  }),
  created() {
    this.axios
      .get('https://ipapi.co/json')
      .then(res => {
        // console.log(res.data)
        if (res.data.country_code === 'PT') {
          this.showPT = true
        }
      })
  },
  methods: {
    scrollInto(elementId) {
      const section = document.querySelector(`#${elementId}`)
      section.scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style scoped>
html {
  scroll-behavior: smooth !important;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt61Mine {
  font-size: 40px;
}

.punt35Mine {
  font-size: 20px;
}

.punt32Mine {
  font-size: 19px;
}

.punt14Mine {
  font-size: 14px;
}

.punt12Mine {
  font-size: 12px;
}

.capLetterMine {
  text-transform: uppercase;
}

.textBackMine {
  background-color: #0198c7;
}

.roundTextMine {
  border-radius: 500px;
}

.socialMine {
  /* border: 1px solid white; */
  border-radius: 500px;
  margin: 5px;
  margin-top: 8px;
  padding: 3px;
}

.menuSupLinkMine {
  text-decoration: none;
}
</style>
